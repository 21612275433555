<template>
  <section id="section-career-slider" class="pt-0" v-scrollanimation="'up'">
    <div class="career-slider">
      <vue-slick-carousel v-bind="settings">
        <div class="career-slider-item">
          <img class="career-slider-img" src="@/assets/images/career/img-career-3.jpeg" alt="career image" />
        </div>
        <div class="career-slider-item">
          <img class="career-slider-img" src="@/assets/images/career/img-career-4.jpeg" alt="career image" />
        </div>
        <div class="career-slider-item">
          <img class="career-slider-img" src="@/assets/images/career/img-career-5.jpeg" alt="career image" />
        </div>
        <div class="career-slider-item">
          <img class="career-slider-img" src="@/assets/images/career/img-career-6.jpeg" alt="career image" />
        </div>
      </vue-slick-carousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "SliderCareer",
  components: { VueSlickCarousel },
  data: function() {
    return {
      settings: {
        autoplay: true,
        arrows: false,
        dots: true,
        dotsClass: "slick-dots slider-dots-custom",
        edgeFriction: 0.35,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderOptions: {
        type: "loop",
        autoWidth: true,
        gap: "1rem",
        pagination: false,
        focus: window.innerWidth > 768 ? undefined : "center",
        autoplay: true,
        arrows: false,
        padding: {
          right: "5rem",
          left: "5rem",
        },
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeListener);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    resizeListener() {
      this.sliderOptions = {
        ...this.sliderOptions,
        focus: window.innerWidth > 768 ? undefined : "center",
      };
    },
  },
};
</script>
